<template>
  <v-menu :close-on-content-click="closeOnContentClick" offset-y v-model="menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small :color="color" dark v-bind="attrs" v-on="on">
        {{ title }}
      </v-btn>
    </template>

    <v-list>
      <v-list-group v-for="(item, index) in mappedSmClasses" :key="index">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> {{ index }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          @click="handleSelectClass(child)"
          v-for="child in item"
          :disabled="registeredClasses.some((rc) => rc.value === child.value)"
          :key="child.value"
        >
          <v-list-item-content>
            <v-list-item-title>{{ child.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SMClassListMenu",
  props: {
    smClasses: {
      type: Array,
      default: () => [],
    },
    availableClasses: {
      type: Array,
      default: () => [],
    },
    registeredClasses: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Sınıf Atama",
    },
    color: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      closeOnContentClick: false,
      menu: false,
    };
  },
  methods: {
    handleSelectClass(classItem) {
      this.closeOnContentClick = true;
      // Eger ogrencinin kayit oldugu bir egitim varsa ve o egitim baslamissa, yeni secilen egitim islenmeden once kullaniciya uyari ver.
      if (
        this.registeredClasses.some(
          (rc) =>
            rc.education_id === classItem.education_id &&
            this.$moment(rc.starts_at).isBefore(this.$moment())
        )
      ) {
        const params = {
          title: "Sınıf Değiştirme",
          text: "Öğrencinin kayıtlı olduğu bir eğitim var ve bu eğitim başlamış. Sınıf değiştirmek istediğinize emin misiniz?",
          onConfirm: () => {
            return this.selectClass(classItem);
          },
        };
        this.$approvalModal.show(params);
      } else {
        this.selectClass(classItem);
      }
    },
    selectClass(classItem) {
      this.$emit("select:register", classItem);
    },
  },
  computed: {
    mappedSmClasses() {
      const returnedClasses = this.smClasses.reduce((acc, curr) => {
        const temp = { ...acc };
        if (
          this.availableClasses.includes(curr.education_id) &&
          this.$moment(curr.ends_on).isAfter(this.$moment())
        ) {
          if (temp[curr.education.name]) {
            temp[curr.education.name].push({
              text: curr.code,
              value: curr.id,
              education_id: curr.education_id,
              starts_at: curr.starts_on,
            });
          } else {
            temp[curr.education.name] = [
              {
                text: curr.code,
                value: curr.id,
                education_id: curr.education_id,
                starts_at: curr.starts_on,
              },
            ];
          }
        }
        return temp;
      }, []);
      return returnedClasses;
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        this.closeOnContentClick = false;
      }
    },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
