<template>
  <SMListTemplate title="Öğrenci Listesi">
    <template #actions>
      <div class="d-flex align-items-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cursor-pointer"
              dark
              @click="() => fetchStudents()"
              v-bind="attrs"
              v-on="on"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          dark
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          append-icon="search"
        ></v-text-field>
      </div>
    </template>
    <template #table>
      <v-data-table
        :headers="headers"
        :items="mappedStudents"
        :search="search"
        :loading="loading"
        :items-per-page="100"
        item-key="id"
        :header-props="{ sortIcon: null }"
        disable-sort
      >
        <template #item.name="{ item }">
          <router-link
            :to="{
              name: 'user-edit',
              params: { id: item.id },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #item.phone="{ item }">
          <a :href="'tel:' + item?.phone">
            {{ formatPhoneInput(item?.phone) }}</a
          >
        </template>
        <template #item.email="{ item }">
          <a :href="'mailto:' + item?.email?.email"> {{ item?.email.email }}</a>
        </template>
        <template #item.actions="{ item }">
          <div class="d-flex justify-content-start">
            <SMClassListMenu
              :registeredClasses="item?.registeredClasses"
              :availableClasses="item?.availableClasses"
              :smClasses="smClasses"
              @select:register="registerStudentToClass($event, item.id)"
            />
            <Note
              note_type="note"
              :name="item.name"
              :noteable_id="item.id"
              :noteable_type="'App\\Models\\User'"
            >
              <v-btn x-small color="primary"> Not </v-btn>
            </Note>
          </div>
        </template>
      </v-data-table>
    </template>
  </SMListTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  FETCH_STUDENTS,
  ASSIGN_STUDENT,
} from "@/core/services/store/students.module";

import { FETCH_SM_CLASSES } from "@/core/services/store/definitions/sm-classes.module";

import { formatPhoneInput } from "@/core/helper.js";

import Note from "@/view/content/notes/Note.vue";
import SMListTemplate from "@/view/content/components/SMListTemplate.vue";
import SMClassListMenu from "@/view/content/siliconmade/SM-Class-List-Menu.vue";

export default {
  name: "StudentList",
  components: { SMListTemplate, SMClassListMenu, Note },
  data() {
    return {
      headers: [
        {
          value: "name",
          text: "Isim",
          align: "left",
        },
        {
          value: "email",
          text: "Email",
          align: "left",
        },
        {
          value: "phone",
          text: "Telefon",
          align: "left",
        },
        {
          value: "classTimechoice",
          text: "Sınıf Zamanı Tercihi",
          align: "left",
        },
        {
          value: "thirdBaseChoice",
          text: "3. Aşama Tercihi",
          align: "left",
        },
        {
          value: "G_Past",
          text: "G'",
          align: "left",
        },
        {
          value: "G_Now",
          text: "G",
          align: "left",
        },
        {
          value: "G_Score",
          text: "G Puan",
          align: "left",
        },
        {
          value: "P_Grant",
          text: "G->P Burs",
          align: "left",
        },
        {
          value: "P_Past",
          text: "P'",
          align: "left",
        },
        {
          value: "P_Now",
          text: "P",
          align: "left",
        },
        {
          value: "P_Score",
          text: "P Puan",
          align: "left",
        },
        {
          value: "SM_Grant",
          text: "P->S Burs",
          align: "left",
        },
        {
          value: "S_Past",
          text: "S'",
          align: "left",
        },
        {
          value: "S_Now",
          text: "S",
          align: "left",
        },
        {
          value: "S_Score",
          text: "S Puan",
          align: "left",
        },
        {
          value: "SH_Grant",
          text: "S->H Burs",
          align: "left",
        },
        {
          value: "SH_Past",
          text: "H'",
          align: "left",
        },
        {
          value: "SH_Now",
          text: "H",
          align: "left",
        },
        {
          value: "SH_Score",
          text: "H Puan",
          align: "left",
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "left",
        },
      ],
      loading: true,
      search: "",
    };
  },
  async mounted() {
    try {
      await this.fetchSmClasses();
      await this.fetchStudents();
    } catch (error) {
      let message =
        error[0]?.detail ?? "Bir hata oluştu lütfen tekrar deneyiniz";
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 2000,
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([FETCH_STUDENTS, ASSIGN_STUDENT, FETCH_SM_CLASSES]),
    formatPhoneInput,
    async registerStudentToClass(classItem, studentId) {
      try {
        await this.assignStudent({
          studentId,
          courseId: classItem.value,
        });
        this.$toast.success("Sınıf ataması başarılı", {
          position: "bottom-right",
          duration: 2000,
        });
      } catch (error) {
        let message =
          error[0]?.detail ?? "Bir hata oluştu lütfen tekrar deneyiniz";
        this.$toast.error(message, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    getGoldenmadeInformationOfUsersClasses(smClasses, isAvailable) {
      const goldenmadeInformation = {
        G_Past: !isAvailable ? "X" : "✓",
        G_Now: !isAvailable ? "X" : "✓",
        G_Score: !isAvailable ? "X" : "✓",
        P_Grant: !isAvailable ? "X" : "✓",
      };
      if (!isAvailable) {
        return goldenmadeInformation;
      }
      goldenmadeInformation.G_Score = 0;
      smClasses.forEach((item) => {
        if (item.pivot.is_active) {
          goldenmadeInformation.G_Now = item.code;
          goldenmadeInformation.G_Score = goldenmadeInformation.G_Score
            ? goldenmadeInformation.G_Score + item?.pivot?.point
            : item?.pivot?.point;
          goldenmadeInformation.P_Grant = item?.pivot?.next_stage_grant_rate;
        } else {
          goldenmadeInformation.G_Past = item.code;
          goldenmadeInformation.G_Score = goldenmadeInformation.G_Score
            ? goldenmadeInformation.G_Score + item?.pivot?.point
            : item?.pivot?.point;
        }
      });
      return goldenmadeInformation;
    },
    getPlatiniummadeInformationOfUsersClasses(smClasses, isAvailable) {
      const platiniummadeInformation = {
        P_Past: !isAvailable ? "X" : "✓",
        P_Now: !isAvailable ? "X" : "✓",
        P_Score: !isAvailable ? "X" : "✓",
        SM_Grant: !isAvailable ? "X" : "✓",
      };
      if (!isAvailable) {
        return platiniummadeInformation;
      }
      platiniummadeInformation.P_Score = 0;
      smClasses.forEach((item) => {
        if (item.pivot.is_active) {
          platiniummadeInformation.P_Now = item.code;
          platiniummadeInformation.P_Score = platiniummadeInformation.P_Score
            ? platiniummadeInformation.P_Score + item?.pivot?.point
            : item?.pivot?.point;
          platiniummadeInformation.SM_Grant =
            item?.pivot?.next_stage_grant_rate;
        } else {
          platiniummadeInformation.P_Past = item.code;
          platiniummadeInformation.P_Score = platiniummadeInformation.P_Score
            ? platiniummadeInformation.P_Score + item?.pivot?.point
            : item?.pivot?.point;
        }
      });
      return platiniummadeInformation;
    },
    getSiliconmadeInformationOfUsersClasses(smClasses, isAvailable) {
      const siliconmadeInformation = {
        S_Past: !isAvailable ? "X" : "✓",
        S_Now: !isAvailable ? "X" : "✓",
        S_Score: !isAvailable ? "X" : "✓",
        SH_Grant: !isAvailable ? "X" : "✓",
      };
      if (!isAvailable) {
        return siliconmadeInformation;
      }
      siliconmadeInformation.S_Score = 0;
      smClasses.forEach((item) => {
        if (item.pivot.is_active) {
          siliconmadeInformation.S_Now = item.code;
          siliconmadeInformation.S_Score = siliconmadeInformation.S_Score
            ? siliconmadeInformation.S_Score + item?.pivot?.point
            : item?.pivot?.point;
          siliconmadeInformation.SH_Grant = item?.pivot?.next_stage_grant_rate;
        } else {
          siliconmadeInformation.S_Past = item.code;
          siliconmadeInformation.S_Score = siliconmadeInformation.S_Score
            ? siliconmadeInformation.S_Score + item?.pivot?.point
            : item?.pivot?.point;
        }
      });
      return siliconmadeInformation;
    },
    getSiliconheadInformationOfUsersClasses(smClasses, isAvailable) {
      const siliconheadInformation = {
        SH_Past: !isAvailable ? "X" : "✓",
        SH_Now: !isAvailable ? "X" : "✓",
        SH_Score: !isAvailable ? "X" : "✓",
      };
      if (!isAvailable) {
        return siliconheadInformation;
      }
      siliconheadInformation.SH_Score = 0;
      smClasses.forEach((item) => {
        if (item.pivot.is_active) {
          siliconheadInformation.SH_Now = item.code;
          siliconheadInformation.SH_Score = siliconheadInformation.SH_Score
            ? siliconheadInformation.SH_Score + item?.pivot?.point
            : item?.pivot?.point;
        } else {
          siliconheadInformation.SH_Past = item.code;
          siliconheadInformation.SH_Score = siliconheadInformation.SH_Score
            ? siliconheadInformation.SH_Score + item?.pivot?.point
            : item?.pivot?.point;
        }
      });
      return siliconheadInformation;
    },
  },
  computed: {
    ...mapGetters(["getStudents", "smClasses"]),
    mappedStudents() {
      return this.getStudents.map((student) => ({
        ...student,
        thirdBaseChoice:
          student?.preferences?.third_base_class_choice ?? "Belirtilmemiş",
        classTimechoice:
          student?.preferences?.preferred_class ?? "Belirtilmemiş",
        ...this.getGoldenmadeInformationOfUsersClasses(
          student.sm_classes.filter((item) => item.education_id === 1),
          student.availableClasses.includes(1)
        ),
        ...this.getPlatiniummadeInformationOfUsersClasses(
          student.sm_classes.filter((item) => item.education_id === 2),
          student.availableClasses.includes(2)
        ),
        ...this.getSiliconmadeInformationOfUsersClasses(
          student.sm_classes.filter((item) => item.education_id === 3),
          student.availableClasses.includes(3)
        ),
        ...this.getSiliconheadInformationOfUsersClasses(
          student.sm_classes.filter((item) => item.education_id === 4),
          student.availableClasses.includes(4)
        ),
        registeredClasses: student.sm_classes.reduce((acc, curr) => {
          if (curr.pivot.is_active) {
            acc.push({
              value: curr.id,
              education_id: curr.education_id,
              text: curr.code,
              starts_at: curr.starts_on,
            });
          }
          return acc;
        }, []),
      }));
    },
  },
};
</script>
